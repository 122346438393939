import React from 'react';
import styles from './styles.module.css'
import {SocialIcon} from 'react-social-icons/component';
import 'react-social-icons/x'
import 'react-social-icons/t.me';




const Footer = () => {
    const currentYear = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
        <div>
        <small>&copy; {currentYear} All rights reserved $PIGEONS</small>
        <div className={styles.socialIconContainer}>
        <SocialIcon url="https://twitter.com/pigeonsmfers" target="_blank" rel="noopener noreferrer" network="x" className={styles.socialIcon}/>
        <SocialIcon url="https://t.me/+zHUZ1ta_rgMyOGM0" target="_blank" rel="noopener noreferrer" network="t.me" className={styles.socialIcon}/>
        </div>
        </div>
    </footer>
  );
};

export default Footer;
