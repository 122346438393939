import React from 'react';
import styles from './styles.module.css'
import banner from '../images/banner.png'


const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <img src={banner} alt="meme#0" className={styles.headerImage} />
      </div>
    </header>
  );
};

export default Header;
