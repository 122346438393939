import React from 'react';
import styles from './styles.module.css';

const Links = () => {
  return (
    <section className={styles.linkSection}>
      <h2>Useful Links</h2>
      <div className={styles.linkContainer}>
        <div className={styles.link}>
          <a href="https://dexscreener.com/" target="_blank" rel="noopener noreferrer">Dexscreener</a>
        </div>
        <div className={styles.link}>
          <a href="https://pump.fun/board" target="_blank" rel="noopener noreferrer">Pump.fun</a>
        </div>
        <div className={styles.link}>
          <a href="https://dexscreener.com/solana/7TYV28DiMAArcfgUUYZX35yzPUTzZtBdsxDL4UGvBGoy" target="_blank" rel="noopener noreferrer">Buy Now</a>
        </div>
        {/* <div className={styles.link}>
          <a href="https://dexscreener.com/" target="_blank" rel="noopener noreferrer">Twitter</a>
        </div>
        <div className={styles.link}>
          <a href="https://dexscreener.com/" target="_blank" rel="noopener noreferrer">Telegram</a>
        </div> */}
        
        {/* Add more links as needed */}
      </div>
      <p>PLEASE NOTE THAT $PIGEONS IS A COMMUNITY-DRIVEN TOKEN CREATED FOR ENTERTAINMENT PURPOSES ONLY AND HOLDS NO INHERENT VALUE. ​PARTICIPATION IN THIS PROJECT IS AT YOUR OWN RISK, AND WE DO NOT PROVIDE ANY GUARANTEES OR ASSURANCES REGARDING ITS PERFORMANCE ​OR VALUE. PLEASE INVEST RESPONSIBLY AND CONDUCT THOROUGH RESEARCH BEFORE ENGAGING IN ANY FINANCIAL ACTIVITIES INVOLVING $PIGEONS.</p>
    </section>
  );
};

export default Links;
