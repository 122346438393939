import React, { useState } from 'react';
import styles from './styles.module.css';

const FAQ = () => {
  const faqItems = [
    {
      question: 'What is $Pigeons?',
      answer: 'Our pigeons are out in force to take a dump on the bad actors in the space. The Heroes we deserve, but not the one we need.',
    },
    {
      question: 'How do I buy it?',
      answer: 'First you need a phantom wallet, and some SOL tokens. Next, go to Raydium website, connect wallet and swap SOL for $Pigeons. Your tokens will then appear in your Solana wallet.',
    },
    {
      question: 'How did we launch?',
      answer: `We launched our token through pump.fun. Once it reaches Raydium, we keep flyin'.`
    },
    {
      question: 'Where can I buy pigeons?',
      answer: (
        <>
          You can buy pigeons : <a href="https://www.pets4homes.co.uk/sale/birds/pigeons/" target="_blank" rel="noopener noreferrer">here</a> or <a href="https://www.pigeonfarms.com/" target="_blank" rel="noopener noreferrer">here</a>.
        </>
      ),
    }
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className={styles['faq-section']}>
      <h2>Frequently Asked Questions</h2>
      <div className={styles['faq-cards-container']}>
        {faqItems.map((item, index) => (
          <div className={styles['faq-card']} key={index}>
            <button
              className={`${styles['faq-question']} ${openIndex === index ? styles['active'] : ''}`}
              onClick={() => toggleFAQ(index)}
            >
              {item.question} {openIndex === index ? '' : ''}
            </button>
            {openIndex === index && (
              <div className={styles['faq-answer']}>
                {typeof item.answer === 'string' ? <p>{item.answer}</p> : item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
