import './App.css';
import Header from './Header';
import Footer from './Footer';
import Presentation from './Main/Presentation';
import FAQ from './Main/FAQ';
import Links from './Main/Links';
import React from 'react';
function App() {
  
  
  return (
    <div className="App">
      <Header />
      <div className='main-content'>
      <Presentation />
      <FAQ />
      <Links />
      </div>
      <Footer />
    </div>
  );
}


export default App;
