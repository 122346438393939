import React from 'react';
import pigeon1 from '../../images/pigeon1.PNG'
import styles from './styles.module.css'
import {SocialIcon} from 'react-social-icons/component';

const Presentation = () => {
  return (
    <section className={styles.presentation}>
      <div className={styles.contentContainer}>
        <div className={styles.imageContainer}>
          <img src={pigeon1} alt="meme#1" />
        </div>
        <div className={styles.textContainer}>
          <h1>"I just got SHIT on by pigeons!!!"</h1>
          <p>
            Meet $PIGEONS! If you're a bad actor in the space, watch out, they're out there for payback!
          </p>
          <h2>
            Tokenomics:
          </h2>
          <div className={styles.cardContainer}>
            <ul className={styles.card}>
              1B Total supply
            </ul>
            <ul className={styles.card}>
              0% buy/sell tax
            </ul>
            <ul className={styles.card}>
              100% initial LP burned
            </ul>
          </div>
          <h3>CA: 7TYV28DiMAArcfgUUYZX35yzPUTzZtBdsxDL4UGvBGoy</h3>
          <div className={styles.socialIconContainer}>
            <SocialIcon url="https://twitter.com/pigeonsmfers" target="_blank" rel="noopener noreferrer" network="x" className={styles.socialIcon}/>
            <SocialIcon url="https://t.me/+zHUZ1ta_rgMyOGM0" target="_blank" rel="noopener noreferrer" network="t.me" className={styles.socialIcon}/>
          </div>
        <div className={styles.linkContainer}>
        <div className={styles.link}>
        <a href="https://dexscreener.com/solana/7TYV28DiMAArcfgUUYZX35yzPUTzZtBdsxDL4UGvBGoy" target="_blank" rel="noopener noreferrer">Buy Now</a>
        </div>
        </div>
        </div>
      </div>
    </section>
  );
};

export default Presentation;
